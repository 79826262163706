import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import MenuUpper from "../components/MenuUpper";
import Content from "../components/Content";
import MenuLower from "../components/MenuLower";
import axios from "axios";
import { apiUrl } from "../environment";

function Home() {
  const navigate = useNavigate();
  const [dropdownStyles, setDropdownStyles] = useState("dropdown-upper hidden");
  const [sizeX, setSizeX] = useState(100.0);
  const autoAnimationCoutner = useRef(0);
  const [gifClasses, setGifClasses] = useState("main-gif box bounce-7");
  const myTimeouts = useRef([]);
  const lastWheelEventTime = useRef(0);
  const sizeRef = useRef(739.0);
  const sizeRefY = useRef(sizeRef / 1, 777777777777778);
  const counter = useRef(0);
  const [logoTitle, setLogoTitle] = useState("M.K");
  const [autoResizeOn, setAutoResizeOn] = useState(true);
  const mobileCounter = useRef(0);
  const lastDelta = useRef(0);
  const animationCounter = useRef(0);
  var touchstartX = 0;
  var touchstartY = 0;
  var touchendX = 0;
  var touchendY = 0;
  const [logoClicked, setLogoClicked] = useState(false);
  const [styles, setStyles] = useState({ color: "black" });
  const [block, setBlock] = useState(false);
  const overlaymedia = useRef(
    "https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExY2ViNjI3YmQxOGY2OGE3NTAwNGJjMDBmZDBhZTJhMTNmODQ4ZGM4MCZlcD12MV9pbnRlcm5hbF9naWZzX2dpZklkJmN0PWc/jRgxeWaimHY3yqxqXg/giphy.gif"
  );
  const [dropDownContent, setDropDownContent] = useState([
    {
      text: "FASHION",
      media: "",
    },
    {
      text: "AUTOMOTIVE",
      media: "",
    },
    {
      text: "STORYTELLING",
      media: "",
    },
    {
      text: "MUSIC VIDEO",
      media: "",
    },
    {
      text: "DOCUMENTARY",
      media: "",
    },
  ]);
  const [contentData, setContentData] = useState({
    text: "REELS",
    path: "",
  });

  function handleDropdown() {
    if (dropdownStyles == "dropdown-upper hidden") {
      setDropdownStyles("dropdown-upper");
    } else {
      setDropdownStyles("dropdown-upper hidden");
    }
  }

  const getAnimationsByCategory = async (category) => {
    try {
      const response = await fetch(`/${category}.json`);

      const data = await response.json();

      return data[Math.floor(Math.random() * data.length)].animation_link;
    } catch (error) {
      console.error(error);
    }
  };

  function handleTouchStart(event) {
    touchstartX = event.changedTouches[0].screenX;
    touchstartY = event.changedTouches[0].screenY;
  }

  function handleTouchEnd(event) {
    touchendX = event.changedTouches[0].screenX;
    touchendY = event.changedTouches[0].screenY;
    handleGesture();
  }

  useEffect(() => {
    async function getStyles() {
      if (
        contentData.text != "REELS" &&
        contentData.text != "M.K" &&
        contentData.text != "INFO"
      ) {
        if (
          [
            "FASHION",
            "AUTOMOTIVE",
            "MUSIC VIDEO",
            "STORYTELLING",
            "DOCUMENTARY",
            "FASHION:",
            "AUTOMOTIVE:",
            "MUSIC VIDEO:",
            "STORYTELLING:",
            "DOCUMENTARY:",
          ].includes(contentData.text)
        ) {
          if (contentData.text.endsWith(":")) {
            overlaymedia.current = await getAnimationsByCategory(
              contentData.text.replace(":", "").toLowerCase()
            );
          } else {
            overlaymedia.current = await getAnimationsByCategory(
              contentData.text.toLowerCase()
            );
          }
          setStyles({
            backgroundColor: "black",
            backgroundImage: "url(" + overlaymedia.current + ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
            color: "white",
          });
        } else {
          setStyles({});
        }
      } else {
        setStyles({});
      }
      if (contentData.text == "INFO") {
        setStyles({
          backgroundColor: "#838383",
        });
      }
    }
    getStyles();
    mobileCounter.current = 0;
    setSizeX(100);
  }, [contentData.text]);

  useEffect(() => {
    window.addEventListener("wheel", handleScrollWrapper);
    window.addEventListener("resize", handleResize);
    window.addEventListener("touchstart", handleTouchStart, false);
    window.addEventListener("touchend", handleTouchEnd, false);
    return function cleanUp() {
      window.removeEventListener("wheel", handleScrollWrapper);
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  function handleResize() {
    setSizeX(100);
    mobileCounter.current = 0;
    animationCounter.current = 1;
  }

  function handleGesture() {
    const currentTime = Date.now();
    let time = 200;
    if (mobileCounter.current == 0) {
      time = 1500;
    }
    console.log(time, currentTime - lastWheelEventTime.current);
    if (currentTime - lastWheelEventTime.current >= time) {
      // Check if 1 second has passed
      lastWheelEventTime.current = currentTime; // Update the last wheel event timestamp
      if (touchendY < touchstartY) {
        var e = { deltaY: 1 };
        handleScroll(e);
      }
      if (touchendY > touchstartY) {
        var e = { deltaY: 0 };
        handleScroll(e);
      }
    }
  }

  function scrollTimeOut() {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const totalChange = 0.25; // Total change in size

    const stepSize = totalChange * 1; // Size change per step

    let newSize = sizeRef.current + totalChange; // New size after one scroll
    newSize += stepSize;
    if (newSize >= 100) {
      setSizeX(newSize);
    }
    if (
      width < 500 ? newSize >= height * 1.777777777777778 : newSize >= width
    ) {
      setSizeX(100);
      stopAutoScroll();
      setTimeout(() => {
        autoAnimationCoutner.current = 0;
        setAutoResizeOn(true);
      }, 500);
    }
  }

  useEffect(() => {
    lastWheelEventTime.current = Date.now();
  }, []);

  function stopAutoScroll() {
    for (let i = 0; i < myTimeouts.current.length; i++) {
      clearTimeout(myTimeouts.current[i]);
    }
    //setMyTimeouts([]);
  }

  function handleAutoScroll() {
    let speed = 20;
    let numSteps = 1500;
    let newTimeouts = [];
    for (let i = 0; i < numSteps; i++) {
      const timerId = setTimeout(scrollTimeOut, i * speed);
      myTimeouts.current.push(timerId);
    }
    if (mobileCounter.current == 3) {
      mobileCounter.current = 0;
    }
  }

  function handleScrollWrapper(e) {
    const currentTime = Date.now();
    if (
      (e.deltaY >= 40 && e.deltaY <= 60) ||
      (e.deltaY >= -60 && e.deltaY <= -40)
    ) {
      if (currentTime - lastWheelEventTime.current >= 500) {
        // Check if 1 second has passed
        lastWheelEventTime.current = currentTime; // Update the last wheel event timestamp
        handleScroll(e);
      }
    }
    if (e.deltaY === 100 || e.deltaY === -100) {
      if (currentTime - lastWheelEventTime.current >= 500) {
        // Check if 1 second has passed
        lastWheelEventTime.current = currentTime; // Update the last wheel event timestamp
        handleScroll(e);
      }
    }
  }

  function handleScroll(e) {
    setAutoResizeOn(false);
    stopAutoScroll();
    const height = window.innerHeight;
    const width = window.innerWidth;
    const totalChange = e.deltaY > 0 ? 1 : -1; // Total change in size
    if (e.deltaY > 0) {
      mobileCounter.current++;
      animationCounter.current++;
    } else {
      if (mobileCounter.current > 0) {
        mobileCounter.current--;
        animationCounter.current--;
      }
    }
    let numSteps = 0;
    let speed = 1;
    if (mobileCounter.current == 1 && e.deltaY > 0) {
      numSteps = width < 500 ? width / 4 : width / 7; // Number of steps to use for animation
    } else if (mobileCounter.current == 2 && e.deltaY > 0) {
      if (width < 500) {
        speed = 0.4;
      }
      numSteps = width < 500 ? height / 2 : width / 6; // Number of steps to use for animation
    } else if (mobileCounter.current == 1 && e.deltaY <= 0) {
      numSteps = width < 500 ? height / 2 : width / 6; // Number of steps to use for animation
    } else if (mobileCounter.current == 2 && e.deltaY <= 0) {
      if (width < 500) {
        speed = 0.4;
      }
      numSteps = width < 500 ? width / 4 : width / 6.5; // Number of steps to use for animation
    } else {
      numSteps = width < 500 ? height : width / 6; // Number of steps to use for animation
    }
    const stepSize = totalChange * 3; // Size change per step

    let newSize = sizeRef.current + totalChange; // New size after one scroll
    for (let i = 0; i < numSteps; i++) {
      setTimeout(() => {
        if (i == Math.floor(numSteps)) {
        }
        newSize += stepSize;
        if (
          width < 500
            ? animationCounter.current > 2
            : animationCounter.current > 3
        ) {
          setSizeX(100);
          setTimeout(() => {
            autoAnimationCoutner.current = 0;
            setAutoResizeOn(true);
          }, 0);
        } else if (newSize >= 100) {
          setSizeX(newSize);
        } else {
          setTimeout(() => {
            autoAnimationCoutner.current = 0;
            setAutoResizeOn(true);
          }, 0);
        }
      }, i * speed); // Add a small delay between each step for smooth animation
    }
    if (mobileCounter.current == 3) {
      mobileCounter.current = 0;
    }
    if (
      width < 500 ? animationCounter.current > 3 : animationCounter.current > 4
    ) {
      animationCounter.current = 1;
    }
  }

  function handleMouseEnter() {
    setLogoTitle("Michał Korzewski");
  }

  function handleMouseLeave() {
    setLogoTitle("M.K");
  }

  function handleLogoOnClick() {
    if (logoTitle == "M.K") {
      setLogoTitle("Michał Korzewski");
    } else {
      setLogoTitle("M.K");
    }
  }

  useEffect(() => {
    sizeRef.current = sizeX;
    sizeRefY.current = (sizeX / 1, 777777777777778);
  }, [sizeX]);
  return (
    <div className="main">
      <div className="overlay" style={styles}>
        <div className="upper-bar">
          <div
            onClick={() => {
              setContentData({ text: "M.K", path: "" });
              autoAnimationCoutner.current = 0;
              setAutoResizeOn(true);
              setLogoClicked(true);
            }}
            className="logo"
          >
            <span
              onClick={handleLogoOnClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {logoTitle}
            </span>
          </div>
          <MenuUpper
            logoClicked={logoClicked}
            setLogoClicked={setLogoClicked}
            dropDownContent={dropDownContent}
            setDropDownContent={setDropDownContent}
            setData={setContentData}
          />
        </div>

        <Content
          data={contentData}
          size={sizeX}
          gifClasses={gifClasses}
          setGifClasses={setGifClasses}
          handleAutoScroll={handleAutoScroll}
          autoResizeOn={autoResizeOn}
          autoAnimationCoutner={autoAnimationCoutner}
        />
        <MenuLower data={contentData} />
      </div>
    </div>
  );
}

export default Home;
